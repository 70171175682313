<script setup>

</script>

<template>
  <div class="spinner-container">
    <span class="loader" />
  </div>
</template>


<style lang="scss" scoped>
@use "@/styles/variables";

.spinner-container {
  display: flex;
  justify-content: center;
  padding: 40px 16px;
}

.loader {
  width: 96px;
  height: 96px;
  border: 5px solid variables.$color-ford-blue-1000;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
