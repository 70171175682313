// router.js
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    redirect: '/assessments'
  },
  {
    path: '/assessments',
    name: 'Assessments',
    component: () => import('@/javascript/views/assessments/index.vue'),
    children: [
      {
        path: '',
        name: 'AssessmentSearch',
        component: () => import('@/javascript/views/assessments/assessment.vue')
      },
      {
        path: 'dealers/:dealerId',
        name: 'DealerDetails',
        component: () => import('@/javascript/views/assessments/dealer-details.vue'),
        props: true
      }
    ]
  },
  {
    path: '/reporting',
    name: 'Reporting',
    component: () => import('@/javascript/views/reporting/reporting.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  window.scrollTo(0, 0);
});

export default router;
